/* ------ Module imports ------ */
import React from 'react';

function LeftChevronIcon() {
  return (
    <svg
      width="6px"
      height="12px"
      viewBox="0 0 8 16"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          fill="#718096"
          fillRule="nonzero"
        >
          <polygon points="1.5167938 7.9688716 7.99151462 1.49415077 7.99151462 0 0.0226430296 7.9688716 7.99151462 15.9377432 7.99151462 14.4435924" />
        </g>
      </g>
    </svg>
  );
}

export default LeftChevronIcon;
