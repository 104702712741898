/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import LeftChevronIcon from 'common/icons/left-chevron';

/* ------ Local components ------ */
import Category from './category';

function Layer(props) {
  const {
    categories,
    close,
    noAnimate,
    onBack,
    onSelectCategory,
    parent,
    selectedCategory,
  } = props;

  return (
    <div className={`bg-white absolute top-0 left-0 w-full h-full ${noAnimate ? '' : 'layer'} ${close ? 'layer-close' : ''}`}>
      {parent && (
        <button
          className="w-full px-4 py-2 border-b border-gray-300 flex items-center"
          onClick={onBack}
          type="button"
        >
          <LeftChevronIcon />
          <p className="ml-2 text-xs text-gray-800">{parent.name}</p>
        </button>
      )}

      {categories.map(category => (
        <Category
          category={category}
          key={category.id}
          onSelect={() => onSelectCategory(category)}
          selected={selectedCategory && selectedCategory.id === category.id}
        />
      ))}
    </div>
  );
}

export default Layer;
