/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Spinner from 'common/spinner';

/* ------ Local components ------ */
import Layer from './layer';

function CategorySelector(props) {
  const {
    categories,
    categoryStack,
    goingBack,
    onBack,
    onSelect,
    onSubmit,
    selectedCategory,
    view,
  } = props;

  function renderError() {
    return (
      <div className="p-6">
        <img
          alt=""
          className="w-16 mb-6"
          src="/assets/img/crying-emoji.png"
        />
        <p className="text-gray-800 font-bold mb-2">Something&apos;s gone wrong!</p>
        <p className="text-xs text-gray-800 font-medium">
          We couldn’t start you a conversation right now. Please try again later.
        </p>
      </div>
    );
  }

  function renderInProgress() {
    return (
      <div className="p-6">
        <img
          alt=""
          className="w-16 mb-6"
          src="/assets/img/speech-balloon.png"
        />
        <p className="text-gray-800 font-bold mb-2">You already have a conversation in progress!</p>
        <p className="text-xs text-gray-800 font-medium">
          Feel free to keep chatting with the salesperson you are talking to. Alternatively, you can
          end the conversation and start a new one if you&apos;d like.
        </p>
      </div>
    );
  }

  if (view === 'loading') {
    return <Spinner />;
  }

  if (view === 'error') {
    return renderError();
  }

  if (view === 'in_progress') {
    return renderInProgress();
  }

  return (
    <div className="background pb-80 min-h-screen flex flex-col">
      <div className="p-6">
        <p className="text-gray-800 font-bold mb-2">What would you like to chat about?</p>
        <p className="text-xs text-gray-800 font-medium">
          Select a category below so that we can match you to the best person to
          handle your question.
        </p>
      </div>

      <div className="flex-1 relative">
        <Layer
          categories={categories}
          noAnimate
          onSelectCategory={onSelect}
          selectedCategory={selectedCategory}
        />

        {categoryStack.map((category, i) => (
          <Layer
            categories={category.children}
            close={i === categoryStack.length - 1 && goingBack}
            key={category.id}
            onBack={onBack}
            onSelectCategory={onSelect}
            parent={category}
            selectedCategory={selectedCategory}
          />
        ))}
      </div>

      <div className="p-2 pb-6 border-t border-gray-300 bg-white">
        <button
          className="w-full p-2 text-sm font-medium text-white text-center rounded bg-blue disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!selectedCategory}
          onClick={onSubmit}
          type="button"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default CategorySelector;
