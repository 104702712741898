/* ------ Module imports ------ */
import axios from 'axios';

/* ------ Config imports ------ */
import config from 'config';

async function request(url, method, data = null) {
  const requestConfig = {
    url: config.urls.api + url,
    method,
  };

  if (data) {
    requestConfig.data = data;
  }

  return axios(requestConfig);
}

function get(url) {
  return request(url, 'GET', null);
}

function post(url, data) {
  return request(url, 'POST', data);
}

export default {
  get,
  post,
};
