/* ------ Module imports ------ */
import React from 'react';

/* ------ Main component ------ */
import CategorySelector from './components/category-selector';

function App() {
  return <CategorySelector />;
}

export default App;
