/* ------ Module imports ------ */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

/* ------ Config ------ */
import config from 'config';

/* ------ Styles ------ */
import './styles';

/* ------ App import ------ */
import App from './app';

/* ------ Init sentry ------ */
Sentry.init({ dsn: config.sentry.dsn });

/* ------ Render the app! ------ */
document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
