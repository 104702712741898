/* ------ Module imports ------ */
import React from 'react';

function Spinner() {
  return (
    <div className="absolute inset-0 w-full h-full flex items-center justify-center">
      <div className="spinner">
        <img
          alt="Loading"
          src="/assets/img/spinner.png"
        />
      </div>
    </div>
  );
}

export default Spinner;
