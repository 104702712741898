/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Config ------ */
import config from 'config';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import CategorySelector from './category-selector.view';

function CategorySelectorContainer() {
  const [categories, setCategories] = useState(null);
  const [categoryStack, setCategoryStack] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [goingBack, setGoingBack] = useState(false);

  const [view, setView] = useState('loading');

  const [signedRequest, setSignedRequest] = useState(null);

  function onBack() {
    setSelectedCategory(null);

    if (categoryStack.length > 0) {
      setGoingBack(true);

      setTimeout(() => {
        setCategoryStack(categoryStack.slice(0, categoryStack.length - 1));
        setGoingBack(false);
      }, 400);
    }
  }

  async function messengerLoaded(threadContext) {
    setSignedRequest(threadContext.signed_request);

    /* ------ Load categories ------ */
    let loadedCategories = null;
    try {
      const { data } = (await api.get(`/facebook/category?signed_request=${threadContext.signed_request}`)).data;
      loadedCategories = data;
    } catch (e) {
      // Silently ignore - loadedCategories will be null so we will show an error
    }

    if (loadedCategories) {
      setCategories(loadedCategories);
    } else {
      setView('error');
      return;
    }

    setView('main');
  }

  useDidMount(() => {
    function setup() {
      MessengerExtensions.getContext(config.appId, messengerLoaded);
    }

    if (window.MessengerExtensions) {
      setup();
    } else {
      window.extAsyncInit = () => {
        setup();
      };
    }
  });

  function onSelect(category) {
    if (category.children) {
      setSelectedCategory(null);
      setCategoryStack([...categoryStack, category]);
    } else {
      setSelectedCategory(category);
    }
  }

  async function onSubmit() {
    setView('loading');

    const postData = {
      signed_request: signedRequest,
    };

    let success = false;
    try {
      await api.post(`/facebook/category/${selectedCategory.id}`, postData);
      success = true;
    } catch (e) {
      // Silently ignore - conversation will stay null so will show an error
    }

    if (success) {
      MessengerExtensions.requestCloseBrowser();
    } else {
      setView('error');
    }
  }

  return (
    <CategorySelector
      categories={categories}
      categoryStack={categoryStack}
      goingBack={goingBack}
      onBack={onBack}
      onSelect={onSelect}
      onSubmit={onSubmit}
      selectedCategory={selectedCategory}
      view={view}
    />
  );
}

export default CategorySelectorContainer;
