/* ------ Module imports ------ */
import React from 'react';

function Category(props) {
  const {
    category,
    onSelect,
    selected,
  } = props;

  return (
    <button
      className="focus:outline-none flex items-center justify-between w-full px-6 py-3 first:border-t border-b border-gray-300"
      onClick={onSelect}
      type="button"
    >
      <p className="text-sm text-gray-800 font-medium">{category.name}</p>
      {selected && <div className="bg-blue rounded-full w-2 h-2" />}
    </button>
  );
}

export default Category;
